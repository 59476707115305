@import '../../assets/scss/variables';

.cjd-editables-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;

  .cjd-badge {
    padding: 5px;
    border: 1px dashed $grey;
    cursor: pointer;

    svg { pointer-events: none }
  }
}

.cjd-stext-wrapper { margin-top: 20px; }